h2 {
  margin: 0;

  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
}

h3 {
  margin: 1rem 0;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
}

.monospace {
  font-family: 'Roboto Mono', 'Courier New', Courier, monospace;
  white-space: pre;
}

.find-text {
  display: block;
  color: transparent;
  position: absolute;
  user-select: none;
}
