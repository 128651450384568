.transition-ease {
  transition: all 300ms ease;
}

.w-0 {
  width: 0 !important;
}

.mw-0 {
  min-width: 0 !important;
}

.cursor-grab {
  cursor: grab;
}
