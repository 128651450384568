// Hover icon button styles (e.g. item exclude button)
.p-button.hover-action {
  width: 2.571rem;
  padding: 0.5rem 0;
  justify-content: center;

  > .p-button-icon {
    transition: all 300ms ease;
  }

  > .hover-icon {
    position: absolute;
    z-index: 1;

    opacity: 0;

    transition: opacity 300ms ease;

    text-shadow: 0 0 3px var(--surface-card);
  }

  // These styles for hover/active
  &:hover,
  &.hover-active {
    > .p-button-icon {
      opacity: 0.4;
    }

    > .hover-icon {
      opacity: 1;
    }
  }

  // These styles for hover & inactive
  &:hover:not(.hover-active) {
    > .p-button-icon {
      opacity: 0.8;
    }
  }
}

// Adjust opacity for light mode
body.light .p-button.hover-action.hover-active .p-button-icon {
  opacity: 0.6;
}

// Icon dropdown styles
.p-dropdown.icon,
.p-multiselect.icon {
  .lab-icon,
  .fa-solid {
    transition: opacity 300ms ease;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    position: absolute;
    bottom: 10px;

    opacity: 0;

    transition: opacity 300ms ease;
  }

  &:hover {
    .lab-icon,
    .fa-solid {
      opacity: 0.3;
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      opacity: 1;
    }
  }
}
